<template>
  <div class="admin">

    <div class="admin-area">

      <div class="row row-gutter-20">
        <div class="col-6">
          <admin-nav></admin-nav>
        </div>
        <div class="col-18">

          <header class="admin-header cf">
            <ul class="nav nav-header cf">
              <li>Marken</li>
            </ul>
            <div class="header-actions">
              <p><router-link to="/admin/vehicle_makes/new" class="button button-orange button-small">Marke hinzufügen ›</router-link></p>
            </div>
          </header>

          <div class="admin-body">

            <div class="search-wrap">
              <input v-model="search_phrase" v-on:input="search_handler" type="text" class="search-field" placeholder="Suchbegriff eingeben...">
            </div>

            <div v-if="loading">
              <div class="loading-wrap">
                <div class="loading-spinner"></div>
              </div>
            </div>
            <div v-else>

              <div v-if="search_loading">
                <div class="loading-wrap">
                  <div class="loading-spinner"></div>
                </div>
              </div>
              <div v-else>

                <table class="table">
                  <thead>
                    <tr>
                      <th width="10%"></th>
                      <th width="50%">Name</th>
                      <th width="30%" style="text-align:center">Fahrzeuge</th>
                      <th width="10%"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="make in vehicle_makes" v-bind:key="make.id">
                      <td v-if="make.logo">
                        <img :src="make.logo" width="30" height="30">
                      </td>
                      <td v-else>
                        <div style="width:30px;height:30px;background:#f1f1f1"></div>
                      </td>
                      <td>
                        <router-link :to="'/admin/vehicle_makes/'+make.id">
                          {{ make.name }}
                        </router-link>
                      </td>
                      <td style="text-align:center">{{ make.vehicle_count }}</td>
                      <td>
                        <router-link :to="'/admin/vehicle_makes/'+make.id">
                          <span class="material-icons">create</span>
                        </router-link>
                      </td>
                    </tr>
                  </tbody>
                </table>

              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import AdminNav from '../../../components/admin_nav'

export default {
  name: 'admin_reservations',
  components: {
    AdminNav
  },
  data () {
    return {
      table: {
        columns: [
          {
            label: 'Name',
            field: 'name',
          }
        ],
      },
      vehicle_makes: [],
      search_phrase: "",
      search_loading: false,
      loading: true
    }
  },
  methods: {
    get_vehicle_makes() {

      axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/vehicle_makes', { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.vehicle_makes = response.data.vehicle_makes;
        this.loading = false;
      })
      .catch(error => {
        console.log(error);
      })

    },
    search_handler() {
      if (this.search_loading == false) {
        this.search_loading = true;
        axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/vehicle_makes?search_phrase='+this.search_phrase, { headers: { Authorization: this.$store.getters.getToken } })
        .then(response => {
          this.vehicle_makes = response.data.vehicle_makes;
          this.search_loading = false;
        })
        .catch(error => {
          console.log(error);
        })
      }
    }
  },
  mounted () {
    this.get_vehicle_makes();
  }
}
</script>

<style lang="scss">

</style>
